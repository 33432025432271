var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column px-6 pt-6 pb-0",staticStyle:{"overflowY":"auto","height":"100%","width":"100%"},attrs:{"fluid":""}},[_c('v-col',{staticClass:"mb-9",staticStyle:{"flex":"0"}},[_c('v-row',{staticClass:"mt-0 mb-6 text-h5 font-weight-bold textBlack--text"},[_vm._v(" "+_vm._s(_vm.$t('deconve.setting.apiKey.manageKeys'))+" ")]),_c('v-row',[_c('div',{staticClass:"d-flex mb-3 align-center",staticStyle:{"flex":"1"},attrs:{"dense":""}},[_c('input-text',{attrs:{"max-width":"440px","left-icon":"mdi-magnify","placeholder":_vm.$t('deconve.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('v-col',{staticClass:"rounded pa-6",staticStyle:{"border":"1px solid","overflowY":"auto"},style:({
      borderColor: _vm.$vuetify.theme.themes.light.border,
      backgroundColor: _vm.$vuetify.theme.themes.light.inputBackground,
    })},[_c('v-row',{staticClass:"mb-3 justify-space-between align-center"},[_c('span',{staticClass:"subtitle-2 text-sm-subtitle-1 font-weight-medium neutralPrimary--text"},[_vm._v(" "+_vm._s(_vm.$t('deconve.setting.apiKeys'))+" ")]),_c('rectangle-button',{attrs:{"color":"primary","icon":"mdi-plus","data-cy":"apikey-button-add","disabled":!_vm.$can('create', 'com.deconve.apikey')},on:{"clicked":_vm.openApiKeysManager}},[_vm._v(" "+_vm._s(_vm.$t('deconve.setting.apiKey.newKey'))+" ")])],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"flex":"1","border":"1px solid"},style:({borderColor: _vm.$vuetify.theme.themes.light.border}),attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.apikeys,"items-per-page":10,"loading":_vm.isLoading,"loading-text":_vm.$t('deconve.loading'),"data-cy":"apikey-data-table","data-dd-privacy":"mask"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,'DD/MM/YYYY')))])]}},{key:"item.tags",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%","justify-content":"center"}},[_c('div',{staticClass:"pa-2 d-flex flex-wrap",staticStyle:{"width":"fit-content"}},_vm._l((item.tags),function(ref){
    var tag = ref.tag;
return _c('tag',{key:tag.id,staticClass:"pr-1 pb-1",attrs:{"small":"","tag-id":tag.id}})}),1)])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('square-button',{attrs:{"outlined":"","data-cy":"apikey-button-more","content-class":"ml-2","color":"border","icon-color":"neutral","icon-name":"mdi-dots-vertical"}},[_c('v-list',[_c('v-list-item',{attrs:{"data-cy":"apikey-button-edit","disabled":!_vm.$can('update', 'com.deconve.apikey')},on:{"click":function($event){return _vm.openApiKeysManager(item)}}},[_vm._v(" "+_vm._s(_vm.$t('deconve.edit'))+" ")]),_c('v-list-item',{attrs:{"data-cy":"apikey-button-delete","disabled":!_vm.$can('delete', 'com.deconve.apikey')},on:{"click":function($event){return _vm.deleteApiKey(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('deconve.delete'))+" ")]),_c('v-list-item',{on:{"click":function($event){return _vm.seePermissions(item.scopes)}}},[_vm._v(" "+_vm._s(_vm.$t('deconve.seePermissions'))+" ")])],1)],1)]}}],null,true)})],1)],1),_c('api-keys-manager-dialog',{ref:"apiKeysDialog"}),_c('confirmation-dialog',{ref:"confirmationDialog"}),_c('scopes-manager-dialog',{ref:"scopesDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }